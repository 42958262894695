import React, { useState, useRef, useEffect } from "react"
import API from "../constants/api"
import { navigate, graphql } from "gatsby"
import { urlB64ToUint8Array, applicationServerPublicKey } from "../helpers"
import Layout from "../components/layout"
import Image from "../components/image"
import ProfileVideoItem from "../components/profile-video-item"
// import ImageGallery from "../components/image-gallery"
import SEO from "../components/seo"

const Profile = ({ pageContext, data }) => {
  const model = pageContext.model
  const videos = data.allMysqlVideos.nodes
  const [isCopying, setIsCopying] = useState(false)
  const [email, setEmail] = useState("")
  const [follow, setFollow] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [swSupport, setSwSupport] = useState(false)
  const [sw, setSw] = useState(null)
  const stickTo = useRef(null)
  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      setSwSupport(true)
      navigator.serviceWorker
        .register("/sw.js", { scope: `/profile/${model.model_url}` })
        .then(function(swReg) {
          console.log("Service Worker is registered", swReg)
          setSw(swReg)
        })
        .catch(function(error) {
          console.error("Service Worker Error", error)
        })
    }
  }, [])
  useEffect(() => {
    if (sw) {
      sw.pushManager.getSubscription().then(function(subscription) {
        const truthy = !(subscription === null)
        if (truthy) {
          setIsSubscribed(true)
          console.log("User IS subscribed.", subscription)
        } else {
          console.log("User is NOT subscribed.")
        }

        // updateBtn();
      })
    }
  }, [sw])
  const handleFollower = () => {
    //If can do push notifications attempt to do that, too.
    console.log("going to send this back to the server")
  }
  const updateSubscriptionOnServer = subscription => {
    if (subscription) {
      const data = JSON.stringify(subscription)

      let formData = new FormData()
      formData.append("jString", data)
      formData.append("site_id", model.mysqlId)
      fetch(`${API.RIVER}add-follower`, {
        method: "POST",
        body: formData,
        headers: {
          "X-XSRF-Header": API.XSRF,
        },
      })
        .then(res => res.json())
        .then(res => console.log(res))
    }
  }
  const subscribeUser = () => {
    if (isSubscribed) {
      console.log("user is already subscribed")
      return
    }
    const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)
    sw.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey,
      })
      .then(function(subscription) {
        console.log("User is subscribed.")

        updateSubscriptionOnServer(subscription)

        setIsSubscribed(true)

        // updateBtn();
      })
      .catch(function(err) {
        console.log("Failed to subscribe the user: ", err)
        console.log(err.message)
        // updateBtn();
      })
  }
  const copyToClipboard = () => {
    setIsCopying(true)
    setTimeout(() => {
      setIsCopying(false)
    }, 5000)

    if (!navigator.clipboard) {
      //fallbackCopyTextToClipboard(text);
      var textArea = document.createElement("textarea")
      textArea.className = "textareahide"
      textArea.value = `https://addfriends.com/profile/${model.model_url}`
      stickTo.current.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand("copy")
      } catch (err) {
        console.error("Could not copy!", err)
      }
      stickTo.current.removeChild(textArea)
      return
    }
    navigator.clipboard
      .writeText(`https://addfriends.com/profile/${model.model_url}`)
      .then(
        function() {
          console.log("Async: Copying to clipboard was successful!")
        },
        function(err) {
          console.error("Async: Could not copy text: ", err)
        }
      )
  }
  return (
    <Layout>
      <SEO
        title={`${model.model_name} Porn Works`}
        description={model.blog}
        can={`profile/${model.model_url}`}
      />
      <div className="main">
        <div className="feed">
          <div className="poster">
            <Image
              alt={model.model_name}
              filename={`${model.model_url}-header.jpg`}
            />
            <div className="inform">
              <div className="container">
                <ul className="list">
                  <li className="item">
                    Last active <span>28 April</span>
                  </li>
                  <li className="item">
                    <span>4,789</span>m views
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section_twocolumns">
            <div className="section_aside">
              <div className="section_information">
                <div className="wrap">
                  <div
                    className="avatar"
                    onClick={() => {
                      if (model.af_site_id !== 0) {
                        navigate(`/${model.model_url}/river`)
                      }
                    }}
                  >
                    <i className="icon-accept-circle"></i>
                    <Image
                      alt={model.model_name}
                      filename={`${model.model_url}-avatar.jpg`}
                    />
                    {/* Going to need to createt his thumb for everyone */}
                  </div>
                </div>
                <div className="inform">
                  <div className="name on">
                    <strong>{model.model_name.split(" ")[0]}</strong>
                    {model.model_name.split(" ")[1]}
                  </div>
                  <div className="email">
                    @<strong>{model.model_name.split(" ")[0]}</strong>
                    {model.model_name.split(" ")[1]}
                  </div>
                  <div className="state">
                    <i className="icon-pin"></i>
                    <span>{model.location}</span>
                  </div>
                  <ul className="social">
                    {model.facebook_url ? (
                      <li className="item">
                        <a
                          href={`https://www.facebook.com/${model.facebook_url}/`}
                        >
                          <i className="icon-fb"></i>
                        </a>
                      </li>
                    ) : null}
                    {model.twitter_url ? (
                      <li className="item">
                        <a href={`https://twitter.com/${model.twitter_url}`}>
                          <i className="icon-twitter"></i>
                        </a>
                      </li>
                    ) : null}

                    {model.snapchat_url ? (
                      <li className="item">
                        <a
                          href={`https://www.snapchat.com/add/${model.snapchat_url}`}
                        >
                          <i className="icon-snapchat"></i>
                        </a>
                      </li>
                    ) : null}

                    {model.instagram_url ? (
                      <li className="item">
                        <a
                          href={`https://www.instagram.com/${model.instagram_url}/`}
                        >
                          <i className="icon-inst"></i>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                  <div className="column_info">
                    <div className="column">
                      <strong>
                        {model.pics > 999
                          ? `${Math.ceil(model.pics / 1000)}`
                          : model.pics}
                      </strong>
                      pics
                    </div>
                    <div className="column">
                      <strong>{model.videos_count}</strong>
                      videos
                    </div>
                    <div className="column">
                      <strong>
                        {model.followers / 1000 < 1
                          ? "1k"
                          : `${Math.ceil(model.followers / 1000)}k`}
                      </strong>
                      followers
                    </div>
                  </div>
                  <div className="section_description">
                    <div className="row">{model.blog}</div>
                  </div>
                </div>
              </div>
              {/* <div className="section_items">
                <h3 className="title">
                  Other <strong>Available Models</strong>
                </h3>
                <ul className="list_avatars">
                  <li className="item">
                    <a href="#" className="avatar_min on">
                      <img src="images/content/avatar.jpg" alt="" />
                    </a>
                  </li>
                  <li className="item">
                    <a href="#" className="avatar_min on">
                      <img src="images/content/avatar.jpg" alt="" />
                    </a>
                  </li>
                  <li className="item">
                    <a href="#" className="avatar_min">
                      <img src="images/content/avatar.jpg" alt="" />
                    </a>
                  </li>
                </ul>
                <a href="#" className="button">
                  <span>Show All</span>
                </a>
              </div> */}
            </div>
            <div className="section_content">
              <div className="section_panel">
                <div className="column" ref={stickTo}>
                  <div className="share_button" onClick={copyToClipboard}>
                    {isCopying ? (
                      <>
                        <i className="icon-accept-circle"></i>
                        <span>Copied!</span>
                      </>
                    ) : (
                      <>
                        <i className="icon-arrow-back"></i>
                        <span>Share</span>
                      </>
                    )}
                  </div>
                  <div className="link_field">
                    <i className="icon-link"></i>
                    <a
                      href={`https://addfriends.com/profile/${model.model_url}`}
                    >
                      addfriends.com/profile/{model.model_url}
                    </a>
                  </div>
                </div>
                <div className="column second">
                  <div
                    className={follow ? "follow_field open" : "follow_field"}
                  >
                    <button
                      type="button"
                      className="button_follow"
                      onClick={() => {
                        setFollow(true)
                        if (swSupport) {
                          subscribeUser()
                        }
                      }}
                    >
                      <span>Follow</span>
                    </button>
                    <div className="follow_form">
                      <div className="field">
                        <div className="wrap">
                          <div className="photo">
                            <img
                              src={`https://static.addfriends.com/images/${model.model_url}.jpg`}
                              alt={model.model_name}
                            />
                          </div>
                          <input
                            type="email"
                            className="input"
                            value={email}
                            placeholder="john@example.com"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="button_submit"
                          onClick={handleFollower}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {videos.map(video => (
                <ProfileVideoItem
                  video={video}
                  key={`pvi${video.mysqlId}`}
                  model_url={model.model_url}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($modelID: Int!) {
    allMysqlVideos(
      filter: { model_id: { eq: $modelID } }
      sort: { fields: mysqlId, order: DESC }
    ) {
      nodes {
        blog
        file
        mysqlId
        title
        promo_sites {
          site_name
          site_url
          link {
            link
          }
        }
      }
    }
  }
`
export default Profile
